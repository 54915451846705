import {
  PricePlanName,
  SubscriptionTier,
} from "@internal/rest/generated/schemas";

export function isLegacyPlanWithAdditionalFees(
  pricePlan: PricePlanName | undefined
): boolean {
  switch (pricePlan) {
    case PricePlanName.founder_2023_09:
    case PricePlanName.base_2023:
    case PricePlanName.core_2023_monthly:
    case PricePlanName.core_2023:
    case PricePlanName.headliner_2023:
    case PricePlanName.headliner_2023_monthly:
      return true;
    default:
      return false;
  }
}

export function isSubscribed(tier: SubscriptionTier): boolean {
  switch (tier) {
    case SubscriptionTier.BASE_2023:
    case SubscriptionTier.SYNDICATE_2024:
      return false;
    case SubscriptionTier.CORE_2023:
    case SubscriptionTier.CORE_2023_MONTHLY:
    case SubscriptionTier.HEADLINER_2023:
    case SubscriptionTier.HEADLINER_2023_MONTHLY:
    case SubscriptionTier.SYNDICATE_PLUS_2024:
    case SubscriptionTier.INSTITUTIONAL_2024:
      return true;
    default:
      return false;
  }
}

export function mapSubscriptionToPricePlan(
  tier: SubscriptionTier
): PricePlanName {
  switch (tier) {
    case SubscriptionTier.BASE_2023:
      return PricePlanName.base_2023;
    case SubscriptionTier.CORE_2023:
    case SubscriptionTier.CORE_2023_MONTHLY:
      return PricePlanName.core_2023;
    case SubscriptionTier.HEADLINER_2023:
    case SubscriptionTier.HEADLINER_2023_MONTHLY:
      return PricePlanName.headliner_2023;
    case SubscriptionTier.SYNDICATE_2024:
      return PricePlanName.standard_2023;
    case SubscriptionTier.SYNDICATE_PLUS_2024:
      return PricePlanName.syndicate_plus_2024;
    case SubscriptionTier.INSTITUTIONAL_2024:
      return PricePlanName.institutional_2024;
    default:
      return PricePlanName.syndicate_2024 as never;
  }
}

export function isActivePricing(plan: PricePlanName) {
  switch (plan) {
    case "syndicate_2024":
    case "syndicate_plus_2024":
    case "institutional_2024":
    case "founder_2024":
      return true;
    default:
      return false;
  }
}

export function isBrandedPricePlan(pp: PricePlanName | undefined): boolean {
  switch (pp) {
    case PricePlanName.core_2023:
    case PricePlanName.core_2023_monthly:
    case PricePlanName.headliner_2023:
    case PricePlanName.headliner_2023_monthly:
    case PricePlanName.syndicate_plus_2024:
    case PricePlanName.institutional_2024:
      return true;
    default:
      return false;
  }
}

export const activeSyndicatePricePlans = [
  PricePlanName.syndicate_2024,
  PricePlanName.syndicate_plus_2024,
  PricePlanName.institutional_2024,
];

export const activeFounderPricePlan = [PricePlanName.founder_2024];

export function isFounderPricing(pp: PricePlanName | undefined): boolean {
  if (
    pp === PricePlanName.founder_2023 ||
    pp === PricePlanName.founder_2023_07 ||
    pp === PricePlanName.founder_2023_09 ||
    pp === PricePlanName.founder_2024
  ) {
    return true;
  }

  return false;
}

export function isPlanWithOffPlatformDeals(pp: PricePlanName | undefined) {
  return pp === PricePlanName.institutional_2024;
}
